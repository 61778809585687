/** @jsx jsx */
import { jsx, Box, Input, Textarea, Button, Paragraph } from "theme-ui";
import { theme } from "gatsby-plugin-theme-ui/index";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useState } from "react";

// Interface and object for the Formik / form values
interface ContactValues {
  name: string;
  email: string;
  phone: string;
  message: string;
  "form-name": string;
}
const contactValues: ContactValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
  "form-name": "ContactForm",
};

// The validation schema for formik
const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

// interface ContactValueKeys

const ContactForm = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (
    values: ContactValues,
    { resetForm }: { resetForm: Function }
  ) => {
    let formData = new FormData();

    for (const key in values) {
      formData.append(key, values[key as keyof ContactValues]);
    }

    const post = await fetch("https://formspree.io/f/mqknrojy", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData as any).toString(),
    });

    if (post.status === 200) {
      setSuccess(true);
      resetForm();
      setTimeout(() => {
        setSuccess(false);
      }, 1200);
    } else {
      setError(true);
      resetForm();
    }
  };

  return (
    <Formik
      initialValues={contactValues}
      validationSchema={ContactSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form
          sx={theme?.forms?.contactForm}
          data-netlify={true}
          name="ContactForm"
          method="POST"
        >
          <Field type="hidden" name="form-name" value="ContactForm" />
          <Field name="name" placeholder="Name" as={Input} />
          <Field name="email" placeholder="Email" as={Input} />
          <Field name="phone" placeholder="Phone" as={Input} />
          <Field name="message" placeholder="Message" as={Textarea} />

          {/* Submit button */}
          {success ? (
            <Paragraph>
              Thanks for submitting! We'll be in touch soon.
            </Paragraph>
          ) : (
            <Button
              sx={{
                mt: 3,
              }}
              variant="submit"
              type="submit"
            >
              Submit
            </Button>
          )}

          {error && (
            <Paragraph sx={{ mt: 2 }}>
              There was a problem with your submission. Try again later.
            </Paragraph>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
