/** @jsx jsx */
import { jsx, Container, Flex, Box, Heading } from 'theme-ui';
import { theme } from 'gatsby-plugin-theme-ui/index';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from 'components/Common/Layout';
import Header from 'components/Header/Header';
import MoveYourBrandCard from 'components/Footer/MoveYourBrandCard';
import ContactForm from 'components/contact/ContactForm';

const ContactPage = ({
  data: {
    strapiContactPage: { SEO },
  },
}) => {
  return (
    <Layout
      headerColor="BLUE"
      seo={{
        title: SEO.meta_title,
        description: SEO.meta_description,
      }}
      sx={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #EBEBEB 100%)',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: '-100px',
        }}
      >
        <StaticImage
          src="../../images/contact-page/white-liquid.svg"
          alt="Design image"
          loading="eager"
          placeholder="blurred"
          width={749}
          height={823}
        />
      </Box>
      <Box
        sx={{
          position: 'relative',
          pt: '215px',
          mb: '80px',
        }}
      >
        <Container variant="container1000">
          <Heading
            sx={{
              textAlign: 'center',
            }}
            as="h1"
            variant="styles.h1"
          >
            Get in touch with us
          </Heading>
        </Container>

        {/* Content */}
        <Container
          variant="container1000"
          sx={{
            overflow: 'hidden',
          }}
        >
          {/* Background image */}

          <Flex
            sx={{
              mt: 5,
              position: 'relative',
              zIndex: 'front',
              flexDirection: ['column-reverse', 'row'],
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                mt: 4,
              }}
            >
              <a href={`mailto:info@motus.agency`} sx={theme?.links?.infoEmail}>
                <span className="iconWrapper">
                  <StaticImage
                    sx={{
                      mr: 1,
                      display: 'inline-block',
                    }}
                    src="../../images/icons/email-icon.svg"
                    alt="email icon"
                    layout="fixed"
                    width={17}
                    height={13}
                  />
                </span>
                info@motus.agency
              </a>
              <MoveYourBrandCard
                sx={{
                  width: ['380px', '386px'],
                  height: ['376px', '366px'],
                  p: [4, 4],
                  mt: [6, 3],
                  flexDirection: ['column-reverse', 'column-reverse'],
                  '.heading': {
                    display: 'none',
                  },
                }}
              />
            </Box>
            <ContactForm />
          </Flex>
        </Container>
      </Box>
    </Layout>
  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage {
    strapiContactPage {
      SEO {
        meta_title
        meta_description
      }
    }
  }
`;
